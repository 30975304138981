exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-content-index-js": () => import("./../../../src/pages/content-index.js" /* webpackChunkName: "component---src-pages-content-index-js" */),
  "component---src-pages-explorer-js": () => import("./../../../src/pages/explorer.js" /* webpackChunkName: "component---src-pages-explorer-js" */),
  "component---src-pages-forecasts-js": () => import("./../../../src/pages/forecasts.js" /* webpackChunkName: "component---src-pages-forecasts-js" */),
  "component---src-pages-image-check-js": () => import("./../../../src/pages/image-check.js" /* webpackChunkName: "component---src-pages-image-check-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-research-drivers-js": () => import("./../../../src/pages/research-drivers.js" /* webpackChunkName: "component---src-pages-research-drivers-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-selections-js": () => import("./../../../src/pages/selections.js" /* webpackChunkName: "component---src-pages-selections-js" */),
  "component---src-pages-signals-js": () => import("./../../../src/pages/signals.js" /* webpackChunkName: "component---src-pages-signals-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-trends-js": () => import("./../../../src/pages/trends.js" /* webpackChunkName: "component---src-pages-trends-js" */),
  "component---src-templates-forecast-js": () => import("./../../../src/templates/Forecast.js" /* webpackChunkName: "component---src-templates-forecast-js" */),
  "component---src-templates-signal-js": () => import("./../../../src/templates/Signal.js" /* webpackChunkName: "component---src-templates-signal-js" */),
  "component---src-templates-trend-js": () => import("./../../../src/templates/Trend.js" /* webpackChunkName: "component---src-templates-trend-js" */)
}

